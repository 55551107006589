<template>
  <v-form>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="record.employees"
          :items="salesEmployees"
          attach
          chips
          clearable
          @input="searchInput=null"
          :search-input.sync="searchInput"
          deletable-chips
          label="Sales Employees"
          item-text="SlpName"
          item-value="id"
          multiple
        ></v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-text-field v-model="record.Tvalue" type="number" label="Target Value"></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          v-model="record.UoM"
          :items="uoms"
          item-text="UomName"
          item-value="id"
          label="Target Metric"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-autocomplete
          v-model="record.skus"
          :items="itemsData"
          attach
          chips
          clearable
          @input="searchInput=null"
          :search-input.sync="searchInput"
          deletable-chips
          label="SKUs"
          item-text="ItemName"
          item-value="id"
          multiple
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <DatePicker @date="setStartDate" :myDate="record.TfromDate" :title="`From Date`"></DatePicker>
      </v-col>

      <v-col cols="4">
        <DatePicker @date="setEndDate" :myDate="record.TtoDate" :title="`To Date`"></DatePicker>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          v-model="record.RecurPat"
          :items="repeatData"
          item-text="name"
          item-value="value"
          label="Repeat"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-textarea
          label="Comments"
          v-model="record.comment"
          auto-grow
          outlined
          document_lines="1"
          row-height="15"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    itemsData: {
      type: Array
    }
  },
  computed: {
    ...mapGetters("salesEmployee", ["salesEmployees"]),
    ...mapGetters("uom", ["uoms"]),
    ...mapGetters(["user"])
  },
  data() {
    return {
      record: {},
      searchInput: null,
      modal2: false,
      time: null,
      repeatData: [
        { name: "Never", value: "N" },
        { name: "Weekly", value: "W" },
        { name: "Fortnightly", value: "F" },
        { name: "Monthly", value: "M" },
        { name: "Annually", value: "A" }
      ]
    };
  },
  methods: {
    setStartDate(date) {
      this.record.StartDate = date;
    },
    setEndDate(date) {
      this.record.EndDate = date;
    },
    sendData() {
      this.$emit("data", this.record);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>